<template>
    <el-col :span="24" style="background-color: #ffffff">
        <el-col :span="24">
            <div class="container">
                <div class="handle-box">
                    <el-date-picker
                        style="float: left; margin-right: 10px"
                        v-model="query.exportTime"
                        type="daterange"
                        align="right"
                        unlink-panels
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        :picker-options="pickerOptions"
                    >
                    </el-date-picker>
                    <el-select v-model="query.Type" placeholder="用户类别" style="float: left; margin-right: 10px">
                        <el-option v-for="item in query.typeList" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                    </el-select>
                    <el-input v-model="query.idNum" placeholder="输入身份证" class="handle-input mr10"></el-input>
                    <el-input v-model="query.ucn" placeholder="输入统一认证ID" class="handle-input mr10"></el-input>
                    <el-button type="primary" icon="el-icon-search" @click="handleSearch">导出</el-button>
                </div>
                <el-table :data="tableData" border class="table" ref="multipleTable" header-cell-class-name="table-header">
                    <el-table-column prop="id" label="ID" width="100" align="center"></el-table-column>
                    <!-- <el-table-column prop="name" label="导出人" align="center"></el-table-column> -->
                    <el-table-column prop="term" label="条件" align="center"></el-table-column>
                    <el-table-column prop="time" label="创建时间" align="center"></el-table-column>
                    <el-table-column label="导出状态" align="center">
                        <template slot-scope="scope">
                            <el-tag type="danger" v-if="scope.row.state == 1">正在渲染，请稍侯再刷新</el-tag>
                            <el-tag v-if="scope.row.state == 2">渲染完成</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" align="center" style="display: flex; justify-content: center; align-items: center">
                        <template slot-scope="scope" style="display: flex" @click="handleSelect(scope.row)">
                            <div style="display: flex">
                                <el-button
                                    type="text"
                                    style="margin-right: 10px"
                                    icon="el-icon-edit"
                                    @click="downExcellBut(scope.$index, scope.row)"
                                    >下载</el-button
                                >
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
                <div class="pagination">
                    <el-pagination
                        background
                        layout="total, prev, pager, next"
                        :current-page="query.pageIndex"
                        :page-size="query.pageSize"
                        :total="pageTotal"
                        @current-change="handlePageChange"
                    ></el-pagination>
                </div>
            </div>
        </el-col>
    </el-col>
</template>

<script>
import { fetchData } from '../../api/index';
export default {
    name: 'basetable',
    data() {
        return {
            query: {
                pageIndex: 1,
                pageSize: 10
            },
            tableData: [],
            pageTotal: 0,
            reviseUserstate: false,
            reviseUser: {},
            pickerOptions: {
                shortcuts: [
                    {
                        text: '最近一周',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                            picker.$emit('pick', [start, end]);
                        }
                    },
                    {
                        text: '最近一个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                            picker.$emit('pick', [start, end]);
                        }
                    },
                    {
                        text: '最近三个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                            picker.$emit('pick', [start, end]);
                        }
                    }
                ]
            }
        };
    },
    activated() {},
    created() {
        var that = this;
        var typeList = [
            {
                value: 0,
                label: '全部'
            }
        ];
        // var typeAddList = [];

        // var userType = JSON.parse(window.sessionStorage.getItem('userType'))[3];
        // for (var i = 0; i < userType.length; i++) {
        //     var userTypeObj = {
        //         value: userType[i].id,
        //         label: userType[i].name
        //     };
        //     typeList.push(userTypeObj);
        //     typeAddList.push(userTypeObj);
        // }
        var userType2 = JSON.parse(window.sessionStorage.getItem('userType'));
        for (var i = 1; i < 5; i++) {
            for (var x = 0; x < userType2[i].length; x++) {
                if (i == 1) {
                    var userTypeObj = {
                        value: userType2[i][x].id,
                        label: '(学生)' + userType2[i][x].name
                    };
                } else if (i == 2) {
                    var userTypeObj = {
                        value: userType2[i][x].id,
                        label: '(员工)' + userType2[i][x].name
                    };
                } else if (i == 3) {
                    var userTypeObj = {
                        value: userType2[i][x].id,
                        label: '(临时工)' + userType2[i][x].name
                    };
                } else if (i == 4) {
                    var userTypeObj = {
                        value: userType2[i][x].id,
                        label: '(经营)' + userType2[i][x].name
                    };
                }
                typeList.push(userTypeObj);
            }
        }
        this.query.typeList = typeList;
        this.getgoodList();
    },
    methods: {
        // 获取列表数据
        getgoodList() {
            var that = this;
            var query = {
                data: {
                    page: that.query.pageIndex,
                    limit: that.query.pageSize
                },
                url: '/get_exporInOutList.cls.php'
            };
            fetchData(query).then((res) => {
                that.tableData = res.data;
                that.pageTotal = Number(res.leng) || 0;
            });
        },
        // 下载excel
        downExcellBut(index, row) {
            window.location.href = row.url;
        },
        // 导出
        handleSearch() {
            var that = this;
            if (!this.query.exportTime) {
                this.$message.error('导出日期不能为空');
            } else if (that.query.idNum && !this.checktheform(that.query.idNum)) {
                this.$message.error('身份证格式有误');
            } else {
                var query = {
                    data: {
                        identity: that.query.Type,
                        idNum: that.query.idNum,
                        UCN: that.query.ucn,
                        startTime: that.revisestartTimeBut(this.query.exportTime[0]),
                        endTime: that.revisestartTimeBut(this.query.exportTime[1])
                    },
                    url: '/create_exportInOut.cls.php'
                };
                fetchData(query).then((res) => {
                    if (res.code == 0) {
                        that.$message.success(res.msg);
                        that.getgoodList();
                    } else {
                        that.$message.error(res.msg);
                    }
                });
            }
        },
        // 格式化时间选择器值
        revisestartTimeBut(date) {
            var seperator1 = '/';
            var year = date.getFullYear();
            var month = date.getMonth() + 1;
            var strDate = date.getDate();
            if (month >= 1 && month <= 9) {
                month = '0' + month;
            }
            if (strDate >= 0 && strDate <= 9) {
                strDate = '0' + strDate;
            }
            return year + seperator1 + month + seperator1 + strDate;
        },

        // 身份证校验
        /**
         * 身份证校验算法
         */

        checktheform(card) {
            var that = this;
            //是否为空
            if (card === '') {
                return false;
            }
            //校验长度，类型
            if (that.isCardNo(card) === false) {
                return false;
            }
            //检查省份
            if (that.checkProvince(card) === false) {
                return false;
            }
            //校验生日
            if (that.checkBirthday(card) === false) {
                return false;
            }
            //检验位的检测
            if (that.checkParity(card) === false) {
                return false;
            }
            return true;
        },
        //检查号码是否符合规范，包括长度，类型
        isCardNo(card) {
            //身份证号码为15位或者18位，15位时全为数字，18位前17位为数字，最后一位是校验位，可能为数字或字符X
            var reg = /(^\d{15}$)|(^\d{17}(\d|X)$)/;
            if (reg.test(card) === false) {
                return false;
            }
            return true;
        },
        //取身份证前两位,校验省份
        checkProvince(card) {
            var vcity = {
                11: '北京',
                12: '天津',
                13: '河北',
                14: '山西',
                15: '内蒙古',
                21: '辽宁',
                22: '吉林',
                23: '黑龙江',
                31: '上海',
                32: '江苏',
                33: '浙江',
                34: '安徽',
                35: '福建',
                36: '江西',
                37: '山东',
                41: '河南',
                42: '湖北',
                43: '湖南',
                44: '广东',
                45: '广西',
                46: '海南',
                50: '重庆',
                51: '四川',
                52: '贵州',
                53: '云南',
                54: '西藏',
                61: '陕西',
                62: '甘肃',
                63: '青海',
                64: '宁夏',
                65: '新疆',
                71: '台湾',
                81: '香港',
                82: '澳门',
                91: '国外'
            };
            var province = card.substr(0, 2);
            if (vcity[province] == undefined) {
                return false;
            }
            return true;
        },
        //检查生日是否正确
        checkBirthday(card) {
            var len = card.length;
            //身份证15位时，次序为省（3位）市（3位）年（2位）月（2位）日（2位）校验位（3位），皆为数字
            if (len == '15') {
                var re_fifteen = /^(\d{6})(\d{2})(\d{2})(\d{2})(\d{3})$/;
                var arr_data = card.match(re_fifteen);
                var year = arr_data[2];
                var month = arr_data[3];
                var day = arr_data[4];
                var birthday = new Date('19' + year + '/' + month + '/' + day);
                return this.verifyBirthday('19' + year, month, day, birthday);
            }
            //身份证18位时，次序为省（3位）市（3位）年（4位）月（2位）日（2位）校验位（4位），校验位末尾可能为X
            if (len == '18') {
                var re_eighteen = /^(\d{6})(\d{4})(\d{2})(\d{2})(\d{3})([0-9]|X)$/;
                var arr_data = card.match(re_eighteen);
                var year = arr_data[2];
                var month = arr_data[3];
                var day = arr_data[4];
                var birthday = new Date(year + '/' + month + '/' + day);
                return this.verifyBirthday(year, month, day, birthday);
            }
            return false;
        },
        //校验日期
        verifyBirthday(year, month, day, birthday) {
            var now = new Date();
            var now_year = now.getFullYear();
            //年月日是否合理
            if (birthday.getFullYear() == year && birthday.getMonth() + 1 == month && birthday.getDate() == day) {
                //判断年份的范围（3岁到100岁之间)
                var time = now_year - year;
                if (time >= 3 && time <= 100) {
                    return true;
                }
                return false;
            }
            return false;
        },
        //校验位的检测
        checkParity(card) {
            //15位转18位
            card = this.changeFivteenToEighteen(card);
            var len = card.length;
            if (len == '18') {
                var arrInt = new Array(7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2);
                var arrCh = new Array('1', '0', 'X', '9', '8', '7', '6', '5', '4', '3', '2');
                var cardTemp = 0,
                    i,
                    valnum;
                for (i = 0; i < 17; i++) {
                    cardTemp += card.substr(i, 1) * arrInt[i];
                }
                valnum = arrCh[cardTemp % 11];
                if (valnum == card.substr(17, 1)) {
                    return true;
                }
                return false;
            }
            return false;
        },
        //15位转18位身份证号
        changeFivteenToEighteen(card) {
            if (card.length == '15') {
                var arrInt = new Array(7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2);
                var arrCh = new Array('1', '0', 'X', '9', '8', '7', '6', '5', '4', '3', '2');
                var cardTemp = 0,
                    i;
                card = card.substr(0, 6) + '19' + card.substr(6, card.length - 6);
                for (i = 0; i < 17; i++) {
                    cardTemp += card.substr(i, 1) * arrInt[i];
                }
                card += arrCh[cardTemp % 11];
                return card;
            }
            return card;
        },
        // 分页导航
        handlePageChange(val) {
            this.pageIndex = val;
            this.$set(this.query, 'pageIndex', val);
            this.getgoodList();
        },
        inputChange() {
            this.$forceUpdate();
        }
    }
};
</script>

<style scoped>
.el-cascader {
    width: 90%;
    padding-right: 10px;
}
.demonstration {
    color: #948c8c;
}
.handle-box {
    margin-bottom: 20px;
}
.handle-box div {
    margin-bottom: 20px;
}

.handle-select {
    width: 120px;
}

.handle-input {
    width: 200px;
    display: inline-block;
}
.table {
    width: 100%;
    font-size: 14px;
}
.red {
    color: #ff0000;
}
.mr10 {
    margin-right: 10px;
}
.table-td-thumb {
    display: block;
    margin: auto;
    width: 40px;
    height: 40px;
}
.block2 {
    display: flex;
    align-items: center;
    margin-bottom: 18px;
}
</style>
